import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { useIntl, Link } from "@intractive/gatsby-plugin-react-intl";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from '../../components/Layout'

const style = require("./index.module.css");

const PersonListItem = ({ person }) => {
  return (
    <li className="person align-center" itemScope itemType="http://schema.org/Person" key={person.slug}>
      <Link to={`/mensen/${person.slug}`}>
        {person.image ?
          <div className={style.personImageContainer}>
            <GatsbyImage
              image={person.image.gatsbyImageData}
              className="round-image profielfoto"
              itemProp="image"
              alt={person.name} />
          </div>
          :
          <div className={style.personImageContainer}>
            <img className="round-image profielfoto" src="/images/placeholder.jpg" alt="Placeholder" />
          </div>
        }
        <div className="person-name" itemProp="name">{person.name}</div>
        {person.jobtitle &&
          <div className="person-jobTitle" itemProp="jobTitle">{person.jobtitle}</div>
        }
      </Link>
    </li>
  )
}

const PageMensen = ({ data }) => {
  const intl = useIntl();
  return (
    <Layout>
      <Helmet>
        <title>{data.page.title} · STIP</title>
      </Helmet>
      <div className="row main-content">
        <div className="large-3 medium-3 columns sidebar hide-for-small">
          {data.page.subpages &&
            <div className="nav-sub">
              <ul>
                <li><Link to={`/${data.page.slug}/`} activeClassName={style.active}>{data.page.title}</Link>
                  <ul>
                    {data.page.subpages && data.page.subpages.map(function(subpage, index) {
                      return (
                        <li key={index}><Link to={`/${data.page.slug}/${subpage.slug}/`} activeClassName={style.active}>{subpage.pageTitle}</Link></li>
                      )
                    })}
                  </ul>
                </li>
              </ul>
            </div>
          }
        </div>
        <div className="large-9 medium-9 columns">
          <h1>{data.page.pageTitle ? data.page.pageTitle : data.page.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: data.page.text.childMarkdownRemark.html,
            }}
          />
          <h2 id="fractie">{intl.formatMessage({ id: "Fractie" })}</h2>
          <p>
            {intl.formatMessage({ id: "De fractie van STIP gaat over de politiek inhoudelijke kant van de partij, zoals het opstellen van de politieke lijn en het debatteren in de raad, en is met 6 zetels de grootste partij in Delft." })}
            {" "}
            {intl.formatMessage({ id: "Voor vragen kan je de fractie bereiken op" })}{" "}<a href="mailto:fractie@stipdelft.nl">fractie@stipdelft.nl</a>
          </p>
          <p>{intl.formatMessage({ id: "Ben je op zoek naar de resultaten van de fractie?" })}{" "}<Link to="/speerpunten/moties/">{intl.formatMessage({ id: "Bekijk de motiepagina" })}</Link></p>
          <ul className="medium-block-grid-3 small-block-grid-2 person-listing">
            {data.page.fractie && data.page.fractie.map(function(person, index) {
              return (
                <PersonListItem person={person} key={index} />
              );
            })}
          </ul>
          <h2 id="wethouder">{intl.formatMessage({ id: "Wethouder" })}</h2>
          <p>
            {intl.formatMessage({ id: "Sinds 1998 levert STIP een wethouder die dagelijks bestuurder is van Delft." })}{" "}
            {intl.formatMessage({ id: "Deze periode heeft onze wethouder de portefeuilles" })}
          </p>
          <ul className="medium-block-grid-3 small-block-grid-2 person-listing">
            {data.page.wethouder && data.page.wethouder.map(function(person, index) {
              return (
                <PersonListItem person={person} key={index} />
              );
            })}
          </ul>
          <h2 id="bestuur">{intl.formatMessage({ id: "Bestuur" })}</h2>
          <p>
            {intl.formatMessage({ id: "Het bestuur is verantwoordelijk voor de vereniging STIP. Zij organiseren de maandelijke STIP-borrel en onze externe activiteiten." })}{" "}
            {intl.formatMessage({ id: "Daarnaast houdt het bestuur de partij draaiend door bijvoorbeeld de ledenlijst en financiën te beheren." })}{" "}
            {intl.formatMessage({ id: "Voor vragen kan je het bestuur bereiken op" })}{" "}<a href="mailto:bestuur@stipdelft.nl">bestuur@stipdelft.nl</a>
          </p>
          <ul className="medium-block-grid-3 small-block-grid-2 person-listing">
            {data.page.bestuur && data.page.bestuur.map(function(person, index) {
              return (
                <PersonListItem person={person} key={index} />
              );
            })}
          </ul>
          <h2 id="vertrouwenscontactpersonen">{intl.formatMessage({ id: "Vertrouwenscontactpersonen" })}</h2>
          <p>
            {intl.formatMessage({ id: "Als vereniging vinden we het belangrijk dat iedereen zich op diens gemak voelt, en dat er over gepraat kan worden als dat niet het geval is." })}{" "}
            {intl.formatMessage({ id: "Heb je iets meegemaakt of gezien wat je niet fijn vond?" })}{" "}
            {intl.formatMessage({ id: "Wij zijn altijd beschikbaar om in vertrouwen naar iedereen die daar behoefte aan heeft te luisteren en samen te kijken of een vervolg nodig en gewenst is." })}{" "}
            {intl.formatMessage({ id: "In vertrouwen betekent dat wij je verhaal met niemand anders (ook niet het bestuur) bespreken, tenzij je daar toestemming voor geeft." })}{" "}
            {intl.formatMessage({ id: "Je kan contact opnemen via" })}{" "}<a href="mailto:vertrouwenspersonen@stipdelft.nl">vertrouwenspersonen@stipdelft.nl</a>
          </p>
          <ul className="medium-block-grid-3 small-block-grid-2 person-listing">
            {data.page.vertrouwenscontactpersonen && data.page.vertrouwenscontactpersonen.map(function(person, index) {
              return (
                <PersonListItem person={person} key={index} />
              );
            })}
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default PageMensen

export const query = graphql`
  query($node_locale: String) {
    page: contentfulPagePersonen(node_locale: {eq: $node_locale}) {
      title
      slug
      pageTitle
      text {
        childMarkdownRemark {
          html
        }
      }
      subpages {
        pageTitle
        slug
      }
      fractie {
        id
        name
        slug
        jobtitle
        image {
          gatsbyImageData(formats: WEBP, width: 500)
        }
      }
      wethouder {
        id
        name
        slug
        jobtitle
        image {
          gatsbyImageData(formats: WEBP, width: 500)
        }
      }
      bestuur {
        id
        name
        slug
        jobtitle
        image {
          gatsbyImageData(formats: WEBP, width: 500)
        }
      }
      vertrouwenscontactpersonen {
        id
        name
        slug
        jobtitle
        image {
          gatsbyImageData(formats: WEBP, width: 500)
        }
      }
    }
  }
`
